export default {
  florida: {
    value: 11,
    label: 'Florida',
    abbrev: 'fl',
    press_association: '',
    press_association_ssid: null,
    foundation: 'https://floridapressfoundation.org',
    key: 'florida',
    logo: '/FPF-color.png',
    support_email: 'ktower@flpress.com',
    press_release:
      'https://editorials.column.us/blog/column-builds-fundraising-platform-for-florida-press-foundation/',
    subdomain: 'fpf',
    meta_title: 'Florida Press Foundation',
    check_address:
      'Florida Press Foundation\n1025 Greenwood Blvd. suite 191\nLake Mary, Fl. 32746',
    memo_line: 'Community News Fund -',
    prices: {
      prod: [
        { id: 'price_1HTXNgHmcZCnOTsLdGd4AGnA', amount: 1000 },
        { id: 'price_1HTXNeHmcZCnOTsLaZLcwoCq', amount: 2500 },
        { id: 'price_1HTXNfHmcZCnOTsLRi04OWP8', amount: 5000 },
        { id: 'price_1HTXNfHmcZCnOTsLwXGSXq24', amount: 10000 },
        { id: 'price_1HTXNfHmcZCnOTsLxveyH7eA', amount: 25000 }
      ],
      test: [
        { id: 'price_1HOKuZHmcZCnOTsL7QyeBbAj', amount: 1000 },
        { id: 'price_1HOKuaHmcZCnOTsLxC26lzyd', amount: 2500 },
        { id: 'price_1HOKuaHmcZCnOTsLHSMNMPHY', amount: 5000 },
        { id: 'price_1HOKuaHmcZCnOTsLQg0130Yl', amount: 10000 },
        { id: 'price_1HOKuaHmcZCnOTsLymUJhrYR', amount: 25000 }
      ]
    },
    pressQuote: {
      text:
        'Local newspapers play a critical role in our communities, making us all stronger and more engaged citizens. Newspapers are dedicated to keeping you informed. Whether it is a local issue or a worldwide pandemic, they are there to provide consistent news coverage that you can trust.',
      memberName: 'Jim Fogler',
      title: 'CEO, Florida Press Association'
    },
    stateGoals: [
      'More reporters on the street',
      'More local news on our community',
      'More local content on the environment and local businesses'
    ],
    FAQs: [
      {
        q: 'How does my donation get to my local newspaper?',
        a:
          'Donations are processed through the Florida Community News Fund and then sent to the newspaper you select.'
      },
      {
        q: 'Are donations tax deductible?',
        a:
          "Yes, donations are tax deductible to the full extent of the law.\nThe Florida Community News Fund is a program administered by the Florida Press Foundation, tax ID #59-2449377, a 501 (C) (3) organization.\n\nThe Florida Press Foundation is a 501(c)(3) tax-exempt organization with its principal place of business in Florida. The Florida Press Foundation is registered to solicit charitable contributions in Florida. Financial and other information about the Florida Press Foundation's purpose, programs and activities can be obtained by visiting https://floridapressfoundation.org or by writing to Florida Press Foundation at 336 East College Avenue, Suite 304, Tallahassee, FL 32301.\n\nA COPY OF THE OFFICIAL REGISTRATION (CH63336) AND FINANCIAL INFORMATION MAY BE OBTAINED FROM THE DIVISION OF CONSUMER SERVICES BY CALLING 1-800-HELP-FLA (1-800-435-7352) TOLL-FREE WITHIN THE STATE OR BY VISITING HTTPS://WWW.FDACS.GOV/CONSUMER-RESOURCES/CHARITIES AND SELECTING THE CHECK-A-CHARITY SEARCH FEATURE. REGISTRATION DOES NOT IMPLY ENDORSEMENT, APPROVAL, OR RECOMMENDATION BY THE STATE."
      },
      {
        q: 'What payment types do you accept?',
        a:
          'We accept donations by card or check. If by check, payment must be made to the Florida Press Foundation.'
      }
    ],
    hasStateWideSite: true
  },
  kansas: {
    value: 18,
    label: 'Kansas',
    abbrev: 'ks',
    press_association: '',
    press_association_ssid: null,
    foundation: 'https://kspress.com/about-us/kansas-newspaper-foundation',
    key: 'kansas',
    press_release:
      'https://editorials.column.us/blog/kansas-press-foundation-launches-a-donations-campaign-with-column/',
    logo: 'https://kspress.com/sites/all/themes/kspress/img/logo.svg',
    subdomain: 'knf',
    meta_title: 'Kansas Newspaper Foundation',
    prices: {
      prod: [
        { id: 'price_1HW1GAHmcZCnOTsLLvMRet8Y', amount: 1000 },
        { id: 'price_1HW1GBHmcZCnOTsL4XqMIJP6', amount: 2500 },
        { id: 'price_1HW1GAHmcZCnOTsL5oONGGfI', amount: 5000 },
        { id: 'price_1HW1GCHmcZCnOTsL9sNy9HUB', amount: 10000 },
        { id: 'price_1HW1GBHmcZCnOTsLD6u0R47R', amount: 25000 }
      ],
      test: [
        { id: 'price_1HW0r1HmcZCnOTsLsZry6PFt', amount: 1000 },
        { id: 'price_1HW0rAHmcZCnOTsLCeTxZKEy', amount: 2500 },
        { id: 'price_1HW0rIHmcZCnOTsLRLrwXwUi', amount: 5000 },
        { id: 'price_1HW0rQHmcZCnOTsLoPJ5AUbb', amount: 10000 },
        { id: 'price_1HW0rXHmcZCnOTsLEY59ZDhB', amount: 25000 }
      ]
    },
    support_email: 'ajackson@kspress.com',
    check_address:
      'Kansas Newspaper Foundation\n4021 SW 10th St. #351\nTopeka, KS 66604',
    pressQuote: {
      text:
        'Local newspapers play a critical role in our communities making us all stronger and engaged citizens. Newspapers are dedicated to keeping you informed, whether it is a local issue or a worldwide pandemic, they are there to provide consistent news coverage that you can trust.',
      memberName: 'Emily Bradbury',
      title: 'Executive Director, Kansas Press Association'
    },
    stateGoals: [
      'To preserve the legacy, and to advance the prominence of newspapers and the communities they faithfully serve.',
      'To ensure a strong future for the Kansas newspaper industry'
    ],
    FAQs: [
      {
        q: 'How does my donation get to my local newspaper?',
        a:
          'Donations are processed through the Kansas Newspaper Foundation (https://kspress.com/about-us/kansas-newspaper-foundation) and then sent to the newspaper you select.'
      },
      {
        q: 'Are donations tax deductible?',
        a:
          'Yes, donations are tax deductible to the full extent of the law.\nThe Kansas Newspaper Foundation is a program administered by the Kansas Press Association, tax ID #48-0946013, a 501 (C) (3) organization.'
      },
      {
        q: 'What payment types do you accept?',
        a:
          'We accept donations by card or check. If by check, payment must be made to the Kansas Newspaper Foundation.'
      }
    ],
    hasStateWideSite: true
  },
  pennsylvania: {
    value: 40,
    label: 'Pennsylvania',
    abbrev: 'pa',
    us_census_fips_code: '42',
    newzgroup: false,
    my_public_notices: 'http://pa.mypublicnotices.com/',
    public_notice_ads: false,
    custom_site: false,
    tecnavia: false,
    press_association: 'https://www.publicnoticepa.com/',
    press_association_ssid: 431,
    foundation: '',
    legal_notice_dot_org: 39,
    scraper: '',
    key: 'pennsylvania',
    press_release:
      'https://editorials.column.us/blog/kansas-press-foundation-launches-a-donations-campaign-with-column/',
    logo: 'https://kspress.com/sites/all/themes/kspress/img/logo.svg',
    subdomain: 'pna',
    meta_title: 'Pennsylvania NewsMedia Association',
    prices: {
      test: [
        { id: 'price_1HW0r1HmcZCnOTsLsZry6PFt', amount: 1000 },
        { id: 'price_1HW0rAHmcZCnOTsLCeTxZKEy', amount: 2500 },
        { id: 'price_1HW0rIHmcZCnOTsLRLrwXwUi', amount: 5000 },
        { id: 'price_1HW0rQHmcZCnOTsLoPJ5AUbb', amount: 10000 },
        { id: 'price_1HW0rXHmcZCnOTsLEY59ZDhB', amount: 25000 }
      ]
    },
    support_email: 'ajackson@kspress.com',
    check_address:
      'Kansas Newspaper Foundation\n4021 SW 10th St. #351\nTopeka, KS 66604',
    pressQuote: {
      text:
        'Local newspapers play a critical role in our communities making us all stronger and engaged citizens. Newspapers are dedicated to keeping you informed, whether it is a local issue or a worldwide pandemic, they are there to provide consistent news coverage that you can trust.',
      memberName: 'Emily Bradbury',
      title: 'Executive Director, Kansas Press Association'
    },
    stateGoals: [
      'To preserve the legacy, and to advance the prominence of newspapers and the communities they faithfully serve.',
      'To ensure a strong future for the Kansas newspaper industry'
    ],
    FAQs: [
      {
        q: 'How does my donation get to my local newspaper?',
        a:
          'Donations are processed through the Kansas Newspaper Foundation (https://kspress.com/about-us/kansas-newspaper-foundation) and then sent to the newspaper you select.'
      },
      {
        q: 'Are donations tax deductible?',
        a:
          'Yes, donations are tax deductible to the full extent of the law.\nThe Kansas Newspaper Foundation is a program administered by the Kansas Press Association, tax ID #48-0946013, a 501 (C) (3) organization.'
      },
      {
        q: 'What payment types do you accept?',
        a:
          'We accept donations by card or check. If by check, payment must be made to the Kansas Newspaper Foundation.'
      }
    ],
    hasStateWideSite: true
  },

  new_york: {
    value: 34,
    label: 'New York',
    abbrev: 'ny',
    us_census_fips_code: '36',
    newzgroup: false,
    my_public_notices: 'http://ny.mypublicnotices.com/',
    public_notice_ads: false,
    custom_site: false,
    tecnavia: false,
    press_association: 'https://www.newyorkpublicnotices.com/',
    press_association_ssid: null,
    foundation: 'https://nynpa.com/nie.html',
    legal_notice_dot_org: '',
    scraper: '',
    key: 'new_york',
    logo: '/NYNF.png',
    subdomain: 'nynpa',
    meta_title: 'New York Newspapers Foundation',
    prices: {
      prod: [
        { id: 'price_1HoSFWHmcZCnOTsLy0F9SNJF', amount: 1000 },
        { id: 'price_1HoSFWHmcZCnOTsL2LkLx2Lk', amount: 2500 },
        { id: 'price_1HoSFXHmcZCnOTsLUt3vlOjn', amount: 5000 },
        { id: 'price_1HoSFWHmcZCnOTsLw0foj01x', amount: 10000 },
        { id: 'price_1HoSFXHmcZCnOTsL3hZHaqgO', amount: 25000 }
      ],
      test: [
        { id: 'price_1HnjJ1HmcZCnOTsLQFHKcbKv', amount: 1000 },
        { id: 'price_1HnjJ2HmcZCnOTsL2VpBxly1', amount: 2500 },
        { id: 'price_1HnjJ1HmcZCnOTsLcKs8nUrU', amount: 5000 },
        { id: 'price_1HnjJ1HmcZCnOTsLD5KOgYBQ', amount: 10000 },
        { id: 'price_1HnjJ0HmcZCnOTsLpZJN7rGk', amount: 25000 }
      ]
    },
    support_email: 'mmiller@nynpa.com',
    check_address:
      'New York Newspapers Foundation\n252 Hudson Ave\nAlbany, New York 12210',
    pressQuote: {
      text:
        'In our high-tech world, we find ourselves in a constant state of being informed, but this barrage of information, misinformation, distortions and distractions need to be examined critically. Mary Miller is helping to prepare future educators teaching K12 students, to explore their relationships with media, technology, and popular culture by critically questioning different types of representations, through customized hands-on workshops. All current and future teachers need to experience this presentation, it was eye-opening!',
      memberName: 'Kristi Fragnoli',
      title:
        'Ed. D., Professor, Lally School of Education, The College of Saint Rose'
    },
    stateGoals: [
      'How to identify accurate sources',
      'Verify news content',
      'Gain a deeper understanding of how news professionals gather and disseminate credible information, using print or electronic media, and what it means to be a good digital citizen.'
    ],
    goalsSection: {
      title:
        "It's never too early (or late) to educate others to seek the truth.",
      description:
        'Our program is grounded in the belief that news media literacy is an essential element in the formation of active, well-informed citizens. Our interactive presentations are designed to increase media literacy skills including: ',
      postGoalsParagraph: ' '
    },
    heroeSection: {
      header: 'Support news literacy and education for',
      location: 'New York students'
    },
    FAQs: [
      {
        q: 'Are donations tax deductible?',
        a:
          'Yes, donations are tax deductible to the full extent of the law. Contributions like yours play a vital role in enabling the foundation to offer programs and materials to teachers, students and the general public that develop real life strategies to engage individuals in critical thinking about today’s media as well as helping to develop core proficiency skills in news media literacy, understanding our digital footprint and digital citizenship. No goods or services were provided in exchange for this contribution. Your donation is tax-deductible. Our News Media Literacy/Newspaper In Education Program is sponsored by the New York Newspapers Foundation, a tax-exempt 501(c)(3) organization, tax ID #16-1098416.'
      },
      {
        q: 'What payment types do you accept?',
        a:
          'We accept donations by card or check. If by check, payment must be made to the New York Newspapers Foundation.'
      }
    ],
    hasStateWideSite: false,
    noMemoLine: true,
    hasSubscribers: true,
    contactSection: {
      general:
        'For more details about our News Media Literacy program, contact Mary Miller at mmiller@nynpa.com.\nFor questions about donating to support this important First Amendment work, contact Margaret McDermott at mmcdermott@nynpa.com.'
    }
  }
};
