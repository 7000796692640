import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AssociationHome from './components/association/Home';
import NewspaperHome from './components/newspaper/Home';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_VARS, DEV_STRIPE_VARS } from './Constants';
import { Elements } from '@stripe/react-stripe-js';
import { State, SubdomainStatus } from './enums';
import Firebase from './Firebase';
import { EDonationProfile, ESnapshotExists } from './types';

const inTestMode = window.location.pathname.includes('/test');
let stripeKey;
if (inTestMode) stripeKey = DEV_STRIPE_VARS.key;
else stripeKey = STRIPE_VARS.key;

const host = window.location.host;
let stateObj: Record<string, any>;

if (
  window.location.href.includes('netlify.app') ||
  window.location.href.includes('donations-site')
)
  stateObj = State.by_subdomain('fpf');
else if (window.location.href.includes('localhost'))
  stateObj = State.by_subdomain('knf');
else if (host.split('.')[0] === 'staging')
  stateObj = State.by_subdomain(host.split('.')[1]);
else stateObj = State.by_subdomain(host.split('.')[0]);

// loading stripe
const stripePromise = loadStripe(stripeKey);

const App = () => {
  const [subdomainOrg, setSubdomainOrg] = useState<
    ESnapshotExists<EDonationProfile>
  >();
  useEffect(() => {
    // should search by subdomain first!
    if (!stateObj) {
      (async () => {
        const query = await Firebase.firestore()
          .collection('donationProfiles')
          .where('subdomain', '==', host.split('.')[0])
          .get();
        if (
          !query.empty &&
          (query.docs[0].data() as EDonationProfile).subdomainStatus ===
            SubdomainStatus.live.value
        ) {
          stateObj = State.by_value(
            (query.docs[0].data() as EDonationProfile).state
          );
          setSubdomainOrg(
            (query.docs[0] as unknown) as ESnapshotExists<EDonationProfile>
          );
        } else stateObj = State.by_subdomain('knf');
      })();
    }
  }, []);

  if (!stateObj) return <></>;
  return (
    <Elements stripe={stripePromise}>
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/">
              {subdomainOrg ? (
                <NewspaperHome subdomainOrg={subdomainOrg} />
              ) : (
                <AssociationHome stateObj={stateObj} />
              )}
            </Route>
            <Route path="/:name">
              <NewspaperHome />
            </Route>
            <Route path="/:name/test">
              <NewspaperHome />
            </Route>
          </Switch>
        </Router>
      </div>
    </Elements>
  );
};

export default App;
