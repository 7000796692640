import React, { useEffect, useState, useRef } from 'react';
import Firebase from '../../Firebase';
import HeroeSection from './HeroeSection';
import FAQSection from './FAQSection';
import Footer from './Footer';
import Messages from './Messages';
import { EDonationProfile, ESnapshotExists } from '../../types';
import { ENV } from '../../Constants';
import { State } from '../../enums';
import Header from '../../Header';

const NewspaperHome = ({
  subdomainOrg
}: {
  subdomainOrg?: ESnapshotExists<EDonationProfile>;
}) => {
  const [org, setOrg] = useState<ESnapshotExists<EDonationProfile> | undefined>(
    subdomainOrg
  );
  const FAQRef = useRef(null);
  const domain = window.location.pathname.split('/')[1];
  const [showBanner, setShowBanner] = useState(true);
  const inTestMode = window.location.pathname.includes('test');
  const [stateObj, setStateObj] = useState<any>(null);

  useEffect(() => {
    const getOrg = async () => {
      const query = await Firebase.firestore()
        .collection('donationProfiles')
        .where('domain', '==', domain)
        .get();
      const profile = (query.docs[0] as unknown) as ESnapshotExists<
        EDonationProfile
      >;
      if (!query.docs.length) return;
      if (query.docs.length) setOrg(profile);

      const favicon = document.getElementById('favicon') as any;
      favicon.href = profile.data().logoUrl;

      const organization = await profile.data().organization.get();
      const { state } = organization.data()!;
      const stateObj = State.by_value(state);
      setStateObj(stateObj);
    };
    if (!subdomainOrg) getOrg();
    else {
      const { state } = subdomainOrg.data()!;
      const stateObj = State.by_value(state);
      setStateObj(stateObj);
    }
  }, [domain, subdomainOrg]);

  if (!org || !stateObj) return <></>;

  const prices =
    stateObj.prices[ENV === 'PROD' && !inTestMode ? 'prod' : 'test'];

  return (
    <div className="flex flex-col bg-red-100">
      <Header stateObj={stateObj} title={`Support ${org.data().name}`} />
      {inTestMode && showBanner && (
        <div
          className="flex text-white justify-center text-base py-3 w-full"
          style={{ backgroundColor: org.data().color }}
        >
          You’re in test mode! Click “Donate” to make a test donation.
          <span
            className="flex items-center cursor-pointer"
            onClick={() =>
              window.open(
                'https://help.column.us/How-do-I-use-test-mode-to-preview-my-site-4a047f7af92e430eb1f312757166d919'
              )
            }
          >
            <u className="ml-3 mr-2 font-semibold">Learn more</u>
            <svg
              className="mt-1"
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.293 0.292786C8.48053 0.105315 8.73484 0 9 0C9.26516 0 9.51947 0.105315 9.707 0.292786L15.707 6.29279C15.8945 6.48031 15.9998 6.73462 15.9998 6.99979C15.9998 7.26495 15.8945 7.51926 15.707 7.70679L9.707 13.7068C9.5184 13.8889 9.2658 13.9897 9.0036 13.9875C8.7414 13.9852 8.49059 13.88 8.30518 13.6946C8.11977 13.5092 8.0146 13.2584 8.01233 12.9962C8.01005 12.734 8.11084 12.4814 8.293 12.2928L12.586 7.99979H1C0.734784 7.99979 0.48043 7.89443 0.292893 7.70689C0.105357 7.51936 0 7.265 0 6.99979C0 6.73457 0.105357 6.48022 0.292893 6.29268C0.48043 6.10514 0.734784 5.99979 1 5.99979H12.586L8.293 1.70679C8.10553 1.51926 8.00021 1.26495 8.00021 0.999786C8.00021 0.734622 8.10553 0.480314 8.293 0.292786Z"
                fill="white"
              />
            </svg>
          </span>
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            className="absolute top-4 right-0 mr-2 cursor-pointer self-center"
            onClick={() => setShowBanner(false)}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.292787 1.29259C0.480314 1.10512 0.734622 0.999806 0.999786 0.999806C1.26495 0.999806 1.51926 1.10512 1.70679 1.29259L5.99979 5.58559L10.2928 1.29259C10.385 1.19708 10.4954 1.1209 10.6174 1.06849C10.7394 1.01608 10.8706 0.988496 11.0034 0.987342C11.1362 0.986189 11.2678 1.01149 11.3907 1.06177C11.5136 1.11205 11.6253 1.18631 11.7192 1.2802C11.8131 1.37409 11.8873 1.48574 11.9376 1.60864C11.9879 1.73154 12.0132 1.86321 12.012 1.99599C12.0109 2.12877 11.9833 2.25999 11.9309 2.382C11.8785 2.504 11.8023 2.61435 11.7068 2.70659L7.41379 6.99959L11.7068 11.2926C11.8889 11.4812 11.9897 11.7338 11.9875 11.996C11.9852 12.2582 11.88 12.509 11.6946 12.6944C11.5092 12.8798 11.2584 12.985 10.9962 12.9873C10.734 12.9895 10.4814 12.8888 10.2928 12.7066L5.99979 8.41359L1.70679 12.7066C1.51818 12.8888 1.26558 12.9895 1.00339 12.9873C0.741188 12.985 0.490376 12.8798 0.304968 12.6944C0.11956 12.509 0.0143906 12.2582 0.0121121 11.996C0.00983372 11.7338 0.110629 11.4812 0.292787 11.2926L4.58579 6.99959L0.292787 2.70659C0.105316 2.51907 0 2.26476 0 1.99959C0 1.73443 0.105316 1.48012 0.292787 1.29259Z"
              fill="white"
            />
          </svg>
        </div>
      )}
      <HeroeSection {...{ FAQRef, org, inTestMode, prices, stateObj }} />
      <div ref={FAQRef}>
        <FAQSection {...{ org, stateObj }} />
      </div>
      <Footer org={org} />
      <Messages org={org} />
    </div>
  );
};

export default NewspaperHome;
