import React, { useState } from 'react';
import { EDonationProfile, ESnapshotExists } from '../../types';
import PaymentModal from '../PaymentModal';
import styled from 'styled-components';

const HeroeSection = ({
  org,
  FAQRef,
  inTestMode,
  prices,
  stateObj
}: {
  org: ESnapshotExists<EDonationProfile>;
  FAQRef: React.RefObject<HTMLDivElement>;
  inTestMode: boolean;
  stateObj: Record<string, any>;
  prices: { id: string; amount: string }[];
}) => {
  const [menuOPen, setMenuOpen] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const { website, color, pdfUrl, about, city } = org.exists && org.data();
  const {
    hasStateWideSite,
    logo,
    foundation,
    abbrev,
    label,
    heroeSection
  } = stateObj;
  const HoverSpan = styled.span`
    &:hover {
      color: ${color};
    }
  `;
  return (
    <div className="relative bg-gray-100 overflow-hidden">
      <div className="max-w-screen-xl mx-auto">
        <div className="relative z-10 pb-8 bg-gray-100 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-40 text-gray-100 transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
            <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start">
              <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <img
                    src={logo}
                    className="cursor-pointer"
                    width="70"
                    height="60"
                    alt="Foundation Logo"
                    onClick={() => window.open(foundation)}
                  />
                  <div
                    className="-mr-2 flex items-center md:hidden"
                    onClick={() => setMenuOpen(!menuOPen)}
                  >
                    <button
                      type="button"
                      className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                      id="main-menu"
                      aria-label="Main menu"
                      aria-haspopup="true"
                    >
                      <svg
                        width="6"
                        height="22"
                        viewBox="0 0 6 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.00016 2.83333V2.845M3.00016 11V11.0117M3.00016 19.1667V19.1783M3.00016 4C2.69074 4 2.394 3.87708 2.17521 3.65829C1.95641 3.4395 1.8335 3.14275 1.8335 2.83333C1.8335 2.52391 1.95641 2.22717 2.17521 2.00837C2.394 1.78958 2.69074 1.66666 3.00016 1.66666C3.30958 1.66666 3.60633 1.78958 3.82512 2.00837C4.04391 2.22717 4.16683 2.52391 4.16683 2.83333C4.16683 3.14275 4.04391 3.4395 3.82512 3.65829C3.60633 3.87708 3.30958 4 3.00016 4ZM3.00016 12.1667C2.69074 12.1667 2.394 12.0437 2.17521 11.825C1.95641 11.6062 1.8335 11.3094 1.8335 11C1.8335 10.6906 1.95641 10.3938 2.17521 10.175C2.394 9.95625 2.69074 9.83333 3.00016 9.83333C3.30958 9.83333 3.60633 9.95625 3.82512 10.175C4.04391 10.3938 4.16683 10.6906 4.16683 11C4.16683 11.3094 4.04391 11.6062 3.82512 11.825C3.60633 12.0437 3.30958 12.1667 3.00016 12.1667ZM3.00016 20.3333C2.69074 20.3333 2.394 20.2104 2.17521 19.9916C1.95641 19.7728 1.8335 19.4761 1.8335 19.1667C1.8335 18.8572 1.95641 18.5605 2.17521 18.3417C2.394 18.1229 2.69074 18 3.00016 18C3.30958 18 3.60633 18.1229 3.82512 18.3417C4.04391 18.5605 4.16683 18.8572 4.16683 19.1667C4.16683 19.4761 4.04391 19.7728 3.82512 19.9916C3.60633 20.2104 3.30958 20.3333 3.00016 20.3333Z"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="hidden md:block md:ml-10 md:pr-4">
                {hasStateWideSite && (
                  <HoverSpan
                    className="text-gray-600 transition duration-150 ease-in-out cursor-pointer"
                    onClick={() => window.open('/')}
                  >
                    Campaign
                  </HoverSpan>
                )}
                {website && (
                  <HoverSpan
                    className="ml-8 text-gray-600 transition duration-150 ease-in-out cursor-pointer"
                    onClick={() =>
                      window.open(`https://${website.replace('https://', '')}`)
                    }
                  >
                    Our Site
                  </HoverSpan>
                )}
                <HoverSpan
                  className="ml-8 text-gray-600 transition duration-150 ease-in-out cursor-pointer"
                  onClick={() =>
                    window.scrollTo(
                      0,
                      document.body.scrollHeight ||
                        document.documentElement.scrollHeight
                    )
                  }
                >
                  Contact
                </HoverSpan>
                <HoverSpan
                  className="ml-8 text-gray-600 transition duration-150 ease-in-out cursor-pointer"
                  onClick={() => setShowPaymentModal(true)}
                  style={{ color }}
                >
                  Donate
                </HoverSpan>
              </div>
            </nav>
          </div>
          {menuOPen && (
            <div className="absolute text-left right-0 mr-2 top-16 p-2 transition transform origin-top-right md:hidden">
              <div className="rounded-lg shadow-md">
                <div
                  className="rounded-lg bg-white shadow-xs overflow-hidden"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="main-menu"
                >
                  <div className="px-2 pt-2 pb-3">
                    {hasStateWideSite && (
                      <span
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out cursor-pointer"
                        onClick={() => window.open('/')}
                      >
                        Campaign
                      </span>
                    )}
                    {website && (
                      <span
                        className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out cursor-pointer"
                        onClick={() =>
                          window.open(
                            `https://${website.replace('https://', '')}`
                          )
                        }
                      >
                        Our Site
                      </span>
                    )}
                    <span
                      className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out cursor-pointer"
                      onClick={() =>
                        window.scrollTo(
                          0,
                          document.body.scrollHeight ||
                            document.documentElement.scrollHeight
                        )
                      }
                    >
                      Contact
                    </span>
                    <span
                      className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out cursor-pointer"
                      onClick={() => setShowPaymentModal(true)}
                    >
                      Donate
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}

          <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h2 className="text-4xl leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                {heroeSection ? (
                  heroeSection.header
                ) : (
                  <>
                    Support community
                    <br />
                    journalism in
                  </>
                )}
                <br />
                <span style={{ color: color }}>
                  {heroeSection
                    ? heroeSection.location
                    : `${city}, ${(abbrev || label).toUpperCase()}`}
                </span>
              </h2>
              <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                {about}
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <div
                    id="donate-button"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10 cursor-pointer"
                    style={{ background: color }}
                    onClick={() => setShowPaymentModal(true)}
                  >
                    Donate
                  </div>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <div
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md bg-gray-300 hover:text-indigo-600 hover:bg-gray-200 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10 cursor-pointer"
                    style={{ color: color }}
                    onClick={() =>
                      window.scrollTo(0, FAQRef.current!.offsetTop)
                    }
                  >
                    Learn More
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <div
          className="h-56 w-full bg-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          style={{ backgroundImage: `url(${pdfUrl})` }}
        />
      </div>
      {showPaymentModal && prices && prices.length && (
        <PaymentModal
          org={org}
          inTestMode={inTestMode}
          close={() => setShowPaymentModal(false)}
          statePrices={prices}
          stateObj={stateObj}
        />
      )}
    </div>
  );
};

export default HeroeSection;
