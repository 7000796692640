import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ReactMapboxGl, { Marker } from 'react-mapbox-gl';

import { EDonationProfile, ESnapshotExists } from '../../types';
import Firebase from '../../Firebase';

import PaymentModal from '../PaymentModal';
import PaymentTypeSelectModal from './PaymentTypeSelectModal';
import { Functions, ENV } from '../../Constants';
import Header from '../../Header';

const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoibGVvY29sdW1uIiwiYSI6ImNrZjJmZmtkODFmcGUzMW51YmNuaXlodGUifQ.mmYIbxoFL5ZAdlzaIphxVA',
  boxZoom: false,
  doubleClickZoom: false,
  scrollZoom: false
});

const getLocationParams = () => new URLSearchParams(window.location.search);

const AssociationHome: React.FC<{
  stateObj: Record<string, any>;
}> = ({ stateObj }) => {
  const [org, setOrg] = useState<ESnapshotExists<EDonationProfile>>();
  const [selectedDonationOrg, setSelectedDonationOrg] = useState<
    ESnapshotExists<EDonationProfile>
  >();
  const [participatingPapers, setParticipatingPapers] = useState<
    ESnapshotExists<EDonationProfile>[]
  >([]);
  const [showPaymentTypeSelectModal, setShowPaymentTypeSelectModal] = useState(
    false
  );
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [newspaperSearchValue, setNewspaperSearchValue] = useState('');
  const [searchedPapers, setSearchedPapers] = useState<
    ESnapshotExists<EDonationProfile>[]
  >([]);
  const [subscriptionId] = useState(getLocationParams().get('subscriptionId'));
  const [portalUrl, setPortalUrl] = useState<string>();
  const [prices, setPrices] = useState<{ id: string; amount: string }[]>();
  const domain = window.location.pathname.split('/')[1];
  const searchRef = useRef(null);

  const updateNewspaperSearch = () => {
    setSearchedPapers(
      participatingPapers.filter(
        p =>
          p
            .data()
            .name.toLowerCase()
            .indexOf(newspaperSearchValue.toLowerCase()) !== -1
      )
    );
    window.scrollTo(
      0,
      document.body.scrollHeight || document.documentElement.scrollHeight
    );
  };

  useEffect(() => {
    const favicon = document.getElementById('favicon') as any;
    favicon.href = stateObj.logo;
  }, []);
  useEffect(() => {
    const loadAssociationProfile = async () => {
      const orgQuery = await Firebase.firestore()
        .collection('donationProfiles')
        .where('name', '==', stateObj.meta_title)
        .get();
      let org: any;
      if (orgQuery.docs.length) org = orgQuery.docs[0];

      const participatingPaperQuery = await Firebase.firestore()
        .collection('donationProfiles')
        .where('state', '==', stateObj.value)
        .get();
      setParticipatingPapers(
        participatingPaperQuery.docs.filter(d => d.data().domain) as any
      );
      setOrg(org as any);
      setPrices(stateObj.prices[ENV === 'PROD' ? 'prod' : 'test']);
    };

    loadAssociationProfile();
  }, [domain]);

  const handleCreatePortalSession = async () => {
    const createBillingSession = Firebase.functions().httpsCallable(
      Functions.createBillingSession
    );
    const session = await createBillingSession({
      subscriptionId,
      return_url: window.location.href
    });

    if (session.data.url) {
      await setPortalUrl(session.data.url);
      session.data.url && window.open(session.data.url);
    }
  };

  useEffect(() => {
    if (subscriptionId) handleCreatePortalSession();
  }, [subscriptionId]);

  if (!org) return <></>;

  const { color, website, _geoloc, name, state } = org.data();
  const HoverSpan = styled.span`
    &:hover {
      color: ${color};
    }
  `;

  const mappedPapers = participatingPapers.filter(p => p.data()._geoloc);

  return (
    <>
      <Header stateObj={stateObj} title={org.data().name} />
      {portalUrl && (
        <div className="fixed z-30 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            &#8203;
            <div
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-headline"
                  >
                    Manage your subscription
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm leading-5 text-gray-500">
                      Your browser should have opened up your subscription
                      mangement portal. If it didn't, click manage subscription.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    onClick={() => window.location.assign(portalUrl!)}
                  >
                    Manage subscription
                  </button>
                </span>
                <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    onClick={() => setPortalUrl('')}
                  >
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="bg-gray-100 min-h-screen">
        {showPaymentTypeSelectModal && (
          <PaymentTypeSelectModal
            close={() => setShowPaymentTypeSelectModal(false)}
            startAssociationPayment={() => {
              setSelectedDonationOrg(org);
              setShowPaymentModal(true);
            }}
            color={color!}
            stateObj={stateObj}
            findMyNewspaper={() => {
              setShowPaymentTypeSelectModal(false);
              window.scrollTo(0, (searchRef.current as any).scrollHeight + 300);
            }}
          />
        )}
        {showPaymentModal && prices && (
          <PaymentModal
            close={() => setShowPaymentModal(false)}
            org={selectedDonationOrg!}
            statePrices={prices}
            stateObj={stateObj}
            isInHomePage={org.id === selectedDonationOrg?.id}
            inTestMode={false}
          />
        )}
        <div>
          <div className="max-w-screen-xl mx-auto">
            <div className="relative z-10 pb-8">
              <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
                <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start">
                  <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                    <div className="flex items-center justify-between w-full md:w-auto">
                      <img
                        src={stateObj.logo}
                        className="cursor-pointer"
                        width="70"
                        height="60"
                        alt="Association Logo"
                        onClick={() => window.open(stateObj.foundation)}
                      />
                      <div
                        className="-mr-2 flex items-center md:hidden"
                        onClick={() => setMenuOpen(!menuOpen)}
                      >
                        <button
                          type="button"
                          className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                          id="main-menu"
                          aria-label="Main menu"
                          aria-haspopup="true"
                        >
                          <svg
                            width="6"
                            height="22"
                            viewBox="0 0 6 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.00016 2.83333V2.845M3.00016 11V11.0117M3.00016 19.1667V19.1783M3.00016 4C2.69074 4 2.394 3.87708 2.17521 3.65829C1.95641 3.4395 1.8335 3.14275 1.8335 2.83333C1.8335 2.52391 1.95641 2.22717 2.17521 2.00837C2.394 1.78958 2.69074 1.66666 3.00016 1.66666C3.30958 1.66666 3.60633 1.78958 3.82512 2.00837C4.04391 2.22717 4.16683 2.52391 4.16683 2.83333C4.16683 3.14275 4.04391 3.4395 3.82512 3.65829C3.60633 3.87708 3.30958 4 3.00016 4ZM3.00016 12.1667C2.69074 12.1667 2.394 12.0437 2.17521 11.825C1.95641 11.6062 1.8335 11.3094 1.8335 11C1.8335 10.6906 1.95641 10.3938 2.17521 10.175C2.394 9.95625 2.69074 9.83333 3.00016 9.83333C3.30958 9.83333 3.60633 9.95625 3.82512 10.175C4.04391 10.3938 4.16683 10.6906 4.16683 11C4.16683 11.3094 4.04391 11.6062 3.82512 11.825C3.60633 12.0437 3.30958 12.1667 3.00016 12.1667ZM3.00016 20.3333C2.69074 20.3333 2.394 20.2104 2.17521 19.9916C1.95641 19.7728 1.8335 19.4761 1.8335 19.1667C1.8335 18.8572 1.95641 18.5605 2.17521 18.3417C2.394 18.1229 2.69074 18 3.00016 18C3.30958 18 3.60633 18.1229 3.82512 18.3417C4.04391 18.5605 4.16683 18.8572 4.16683 19.1667C4.16683 19.4761 4.04391 19.7728 3.82512 19.9916C3.60633 20.2104 3.30958 20.3333 3.00016 20.3333Z"
                              stroke="black"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block md:ml-10 md:pr-4">
                    <HoverSpan
                      className="text-gray-600 transition duration-150 ease-in-out cursor-pointer"
                      style={{ color: color }}
                      onClick={() => setShowPaymentTypeSelectModal(true)}
                    >
                      Donate
                    </HoverSpan>
                    <HoverSpan
                      className="ml-8 text-gray-600 transition duration-150 ease-in-out cursor-pointer"
                      onClick={() => window.open(stateObj.press_release)}
                    >
                      About the Campaign
                    </HoverSpan>
                    <HoverSpan
                      className="ml-8 text-gray-600 transition duration-150 ease-in-out cursor-pointer"
                      onClick={() =>
                        window.scrollTo(
                          0,
                          document.body.scrollHeight ||
                            document.documentElement.scrollHeight
                        )
                      }
                    >
                      Contact
                    </HoverSpan>
                  </div>
                </nav>
              </div>
              {menuOpen && (
                <div className="absolute text-left right-0 mr-2 top-16 p-2 transition transform origin-top-right md:hidden">
                  <div className="rounded-lg shadow-md">
                    <div
                      className="rounded-lg bg-white shadow-xs overflow-hidden"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="main-menu"
                    >
                      <div className="px-2 pt-2 pb-3">
                        <span
                          className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out cursor-pointer"
                          onClick={() =>
                            window.open(
                              'https://editorials.column.us/blog/column-builds-fundraising-platform-for-florida-press-foundation/'
                            )
                          }
                        >
                          Campaign
                        </span>
                        {website && (
                          <span
                            className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out cursor-pointer"
                            onClick={() => window.open(`https://${website}`)}
                          >
                            Our Site
                          </span>
                        )}
                        <span
                          className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out cursor-pointer"
                          onClick={() =>
                            window.scrollTo(
                              0,
                              document.body.scrollHeight ||
                                document.documentElement.scrollHeight
                            )
                          }
                        >
                          Contact
                        </span>
                        <span
                          className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out cursor-pointer"
                          onClick={() => setShowPaymentTypeSelectModal(true)}
                        >
                          Donate
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                <div className="text-center">
                  <h2 className="text-4xl leading-10 font-extrabold text-gray-900 text-5xl sm:leading-none">
                    Support community
                    <br />
                    <span style={{ color }}>
                      journalism in {stateObj.label}
                    </span>
                    <br />
                  </h2>
                  <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:max-w-2xl md:mt-5 md:text-xl mx-auto">
                    {stateObj.label}'s newspapers need your help. This giving
                    season, you can help the {stateObj.meta_title} support its
                    member newspapers by donating to our fundraising campaign or
                    to your favorite local newspaper.
                  </p>
                  <div className="mt-5 sm:mt-8 sm:flex justify-center">
                    <div className="rounded-md shadow mr-3 w-full md:w-48">
                      <div
                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10 cursor-pointer"
                        style={{ background: color }}
                        onClick={() => setShowPaymentTypeSelectModal(true)}
                      >
                        Donate
                      </div>
                    </div>
                    <div className="mt-3 sm:mt-0 md:ml-3 w-full md:w-48">
                      <div
                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md bg-gray-300 hover:text-indigo-600 hover:bg-gray-200 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10 cursor-pointer"
                        style={{ color }}
                        onClick={() => window.open(stateObj.press_release)}
                      >
                        Learn More
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
        <Map
          style="mapbox://styles/leocolumn/ckfk0hmim3oeo19qid0fdwwrs"
          containerStyle={{
            height: '80vh',
            width: '100vw',
            zIndex: 1
          }}
          center={[_geoloc!.lng, _geoloc!.lat]}
          zoom={[6]}
        >
          {mappedPapers.map(paper => (
            <Marker
              coordinates={[
                paper.data()._geoloc!.lng,
                paper.data()._geoloc!.lat
              ]}
            >
              <div
                className="cursor-pointer bg-gray-50 p-2 rounded"
                onClick={() => {
                  setSelectedDonationOrg(paper as any);
                  setShowPaymentModal(true);
                }}
              >
                {paper.data().name}
              </div>
            </Marker>
          ))}
        </Map>
        <div ref={searchRef} className="z-10 p-2">
          <div className="pt-12 text-3xl font-medium z-1">
            Keep the presses running
          </div>
          <div className="max-w-2xl mx-auto text-gray-700">
            Donate to one newspaper that you want to support, or{' '}
            <span
              className="underline cursor-pointer"
              onClick={() => {
                setSelectedDonationOrg(org);
                setShowPaymentModal(true);
              }}
            >
              make a general contribution
            </span>{' '}
            to the {name}'s donation campaign. General contributions will be
            shared equally among participating members
          </div>
          <div className="flex max-w-lg mx-auto mt-10 pb-20">
            <input
              placeholder="Enter newspaper name or location"
              className="form-input block w-full sm:text-sm sm:leading-5"
              onChange={e => setNewspaperSearchValue(e.target.value)}
              value={newspaperSearchValue}
              onKeyDown={e => e.key === 'Enter' && updateNewspaperSearch()}
            />
            <button
              type="button"
              className="ml-2 inline-flex items-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white focus:outline-none focus:shadow-outline-indigo transition ease-in-out duration-150"
              style={{
                background: color
              }}
              onClick={updateNewspaperSearch}
            >
              Search
            </button>
          </div>
          {searchedPapers.length !== 0 && (
            <div className="max-w-xl flex mx-auto mt-10 justify-center">
              <div className="">
                <span className="font-normal text-2xl">Click to donate</span>
              </div>
              <div className="ml-10 mt-2">
                {searchedPapers.map(p => (
                  <div>
                    <span className="font-medium">{p.data().name}</span>,{' '}
                    {p.data().city} |{' '}
                    <span
                      className="underline cursor-pointer"
                      onClick={() => {
                        setSelectedDonationOrg(p);
                        window.open(
                          `${window.location.origin}/${p.data().domain}`
                        );
                      }}
                      style={{ color }}
                    >
                      Donate
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="text-left">
          <div className="max-w-7xl  mx-auto py-16 px-4 sm:px-6 lg:px-8 md:ml-8">
            <div className="max-w-lg mx-auto md:max-w-none md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h2 className="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">
                  General Support
                </h2>
                <div className="mt-3">
                  <p className="text-lg leading-7 text-gray-500">
                    If you have general questions about this campaign, please
                    reach out to the {stateObj.meta_title}.
                  </p>
                </div>
                <div className="mt-9">
                  <div className="mt-6 flex">
                    <div className="flex-shrink-0">
                      <svg
                        className="h-6 w-6 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                    </div>
                    <div className="ml-3 text-base leading-6 text-gray-500">
                      <p>{stateObj.support_email}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 md:mt-0">
                <h2 className="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">
                  Technical Support
                </h2>
                <div className="mt-3">
                  <p className="text-lg leading-7 text-gray-500">
                    If you have technical questions about the platform or making
                    a donation, please reach out to the Column team.
                  </p>
                </div>
                <div className="mt-9">
                  <div className="mt-6 flex">
                    <div className="flex-shrink-0">
                      <svg
                        className="h-6 w-6 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                    </div>
                    <div className="ml-3 text-base leading-6 text-gray-500">
                      <p>support@column.us</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:border md:border-solid md:mx-10 md:mt-4"></div>
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="mt-8 md:mt-0 md:order-1">
            <p className="text-center text-base leading-6 text-gray-600">
              &copy; 2020 Column, PBC. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssociationHome;
