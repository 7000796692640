import React, { useState, useEffect } from 'react';
import { EDonationProfile, ESnapshotExists, EDonation } from '../../types';
import Firebase from '../../Firebase';

const Messages = ({ org }: { org: ESnapshotExists<EDonationProfile> }) => {
  const [messages, setMessages] = useState<string[]>([]);
  const [selectedMsg, setSelectedMsg] = useState<number>(0);
  const [opacity, setOpacity] = useState<number>(0);
  const { color } = org.data();

  useEffect(() => {
    const fetchMessages = async () => {
      const donationsQuery = ((
        await Firebase.firestore()
          .collection('donations')
          .where('donationProfileId', '==', org.id)
          .get()
      ).docs as unknown) as ESnapshotExists<EDonation>[];

      const msgs = [];

      for (const donation of donationsQuery) {
        const { displayMessage, message, isAnonymous } = donation.data();
        if (displayMessage !== false && message && !isAnonymous) {
          msgs.push(message);
        }
      }
      setMessages(msgs);
    };

    fetchMessages();
  }, []);

  useEffect(() => {
    if (messages.length && messages.length > 1) {
      setSelectedMsg(selectedMsg => selectedMsg + 1);
      setOpacity(1);
      const interval = setInterval(() => {
        setOpacity(0);

        setTimeout(() => {
          setSelectedMsg(selectedMsg => selectedMsg + 1);
          setOpacity(1);
        }, 1000);
      }, 45000);
      return () => clearInterval(interval);
    } else {
      setOpacity(1);
    }
  }, [messages]);

  if (selectedMsg === undefined || messages.length === 0) return <></>;
  return (
    <div
      className="fixed right-0 bottom-0 m-12 rounded bg-white shadow-lg max-w-xs flex flex-row-reverse z-10 transition-all duration-500 ease-in-out"
      style={{ opacity }}
    >
      <div
        className="w-2 absolute h-full rounded rounded-l-none"
        style={{ background: color }}
      />
      <div className="m-3 text-right mr-5">
        <div className="text-sm text-gray-700 font-normal transition duration-500 ease-in-out">
          “{messages[selectedMsg % messages.length]}”
        </div>
        <div className="font-light text-xs text-gray-700 mt-1">
          - Donor to {org.data().name}
        </div>
      </div>
    </div>
  );
};
export default Messages;
